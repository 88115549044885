import { bitcoinAddressValidation } from "@trident/validators/bitcoin-address-validation";
import { z } from "zod";
import { multiChoiceValidator } from "../shared";

export type InsuranceNeeds = z.infer<typeof insuranceNeedsSchema>;
export const insuranceNeedsSchema = z.object({
  bitcoinStorageAmount: z.coerce
    .number({
      required_error: "Bitcoin storage amount is required",
    })
    .min(25_000, {
      message: "Minimum value is 25,000",
    })
    .max(100_000_000, {
      message:
        "Maximum value is 100,000,000. Please contact us for higher amounts",
    })
    .optional(),
  bitcoinProperty: z
    .object({
      isOwner: multiChoiceValidator,
      titleHolder: z.string().trim().max(10_000).optional(),
    })
    .superRefine((val, ctx) => {
      if (!val.isOwner && !val.titleHolder) {
        return ctx.addIssue({
          code: "custom",
          message:
            "Title holder must be provided if the prospective insured is not the owner of the Bitcoin",
          path: ["titleHolder"],
        });
      }
      return val;
    }),
  bitcoinStorageLocation: z
    .object({
      type: z
        .string({
          required_error: "Bitcoin storage location is required",
        })
        .transform((val) => {
          if (val === "NOT_PURCHASED") {
            return "NOT_PURCHASED";
          } else if (val === "SELF_CUSTODY") {
            return "SELF_CUSTODY";
          } else {
            return "EXCHANGE_CUSTODIAN";
          }
        })
        .superRefine((val, ctx) => {
          const validStrings = [
            "EXCHANGE_CUSTODIAN",
            "SELF_CUSTODY",
            "NOT_PURCHASED",
          ];

          if (!validStrings.includes(val)) {
            return ctx.addIssue({
              code: "custom",
              message: "This field is required",
            });
          }

          return val;
        })
        .optional(),
      exchange_custodian: z.string().trim().max(1_000).optional(),
      self_custody_addresses: z
        .array(
          z.object({
            address: bitcoinAddressValidation.optional(),
          }),
        )
        .optional(),
    })
    .superRefine((val, ctx) => {
      if (
        val.type === "SELF_CUSTODY" &&
        (!val.self_custody_addresses || val.self_custody_addresses.length === 0)
      ) {
        return ctx.addIssue({
          code: "custom",
          message:
            "At least one Bitcoin address must be provided if the Bitcoin is stored in self custody",
          path: ["self_custody_addresses"],
        });
      }

      if (val.type === "EXCHANGE_CUSTODIAN" && !val.exchange_custodian) {
        return ctx.addIssue({
          code: "custom",
          message:
            "Exchange or custodian name must be provided if the Bitcoin is stored with an exchange or custodian",
          path: ["exchange_custodian"],
        });
      }

      return val;
    }),
  otherInsurancePolicies: z
    .object({
      hasApprovedPolicies: multiChoiceValidator,
      policies: z.string().trim().max(10_000).optional(),
    })
    .superRefine((val, ctx) => {
      if (val.hasApprovedPolicies && !val.policies) {
        return ctx.addIssue({
          code: "custom",
          message:
            "Policies must be provided if the prospective insured has approved policies",
          path: ["policies"],
        });
      }
      return val;
    }),
  currentInsurancePolicies: z
    .object({
      hasCurrentPolicies: multiChoiceValidator,
      currentPolicies: z.string().trim().max(10_000).optional(),
    })
    .superRefine((val, ctx) => {
      if (val.hasCurrentPolicies && !val.currentPolicies) {
        return ctx.addIssue({
          code: "custom",
          message:
            "Current policies must be provided if the prospective insured has current policies",
          path: ["currentPolicies"],
        });
      }
      return val;
    }),
  claimBenefitsPaidOut: z
    .object({
      hasClaimBenefitsPaidOut: multiChoiceValidator,
      claimBenefitsPaidOutDetails: z.string().trim().max(10_000).optional(),
    })
    .superRefine((val, ctx) => {
      if (val.hasClaimBenefitsPaidOut && !val.claimBenefitsPaidOutDetails) {
        return ctx.addIssue({
          code: "custom",
          message:
            "Claim benefits paid out details must be provided if the prospective insured has had claim benefits paid out",
        });
      }
      return val;
    }),
  terminatedInsurance: z
    .object({
      hasTerminatedInsurance: multiChoiceValidator,
      terminationDetails: z.string().trim().max(10_000).optional(),
    })
    .superRefine((val, ctx) => {
      if (val.hasTerminatedInsurance && !val.terminationDetails) {
        return ctx.addIssue({
          code: "custom",
          message:
            "Termination details must be provided if the prospective insured has terminated insurance",
          path: ["terminationDetails"],
        });
      }
      return val;
    }),
});

export const partialInsuranceNeedsSchema = z
  .object({
    bitcoinStorageAmount: z.coerce
      .number({
        required_error: "Bitcoin storage amount is required",
      })
      .min(25_000, {
        message: "Minimum value is 25,000",
      })
      .max(100_000_000, {
        message:
          "Maximum value is 100,000,000. Please contact us for higher amounts",
      })
      .optional(),
    bitcoinProperty: z.object({
      isOwner: multiChoiceValidator,
      titleHolder: z.string().optional(),
    }),
    bitcoinStorageLocation: z.object({
      type: z
        .string({
          required_error: "Bitcoin storage location is required",
        })
        .transform((val) => {
          if (val === "NOT_PURCHASED") {
            return "NOT_PURCHASED";
          } else if (val === "SELF_CUSTODY") {
            return "SELF_CUSTODY";
          } else {
            return "EXCHANGE_CUSTODIAN";
          }
        })

        .optional(),
      exchange_custodian: z.string().optional(),
      self_custody_addresses: z
        .array(
          z.object({
            address: bitcoinAddressValidation.optional(),
          }),
        )
        .optional(),
    }),
    otherInsurancePolicies: z.object({
      hasApprovedPolicies: multiChoiceValidator,
      policies: z.string().optional(),
    }),
    currentInsurancePolicies: z.object({
      hasCurrentPolicies: multiChoiceValidator,
      currentPolicies: z.string().optional(),
    }),
    claimBenefitsPaidOut: z.object({
      hasClaimBenefitsPaidOut: multiChoiceValidator,
      claimBenefitsPaidOutDetails: z.string().trim().max(10_000).optional(),
    }),
    terminatedInsurance: z.object({
      hasTerminatedInsurance: multiChoiceValidator,
      terminationDetails: z.string().optional(),
    }),
  })
  .deepPartial();

export type PartialInsuranceNeeds = z.infer<typeof partialInsuranceNeedsSchema>;
