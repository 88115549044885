import { z } from "zod";
import {
  dateOfBirthValidator,
  fullNameValidator,
  multiChoiceValidator,
  zipCodeValidator,
} from "../shared";

export const personalInformation = z.object({
  legalName: fullNameValidator,
  dateOfBirth: dateOfBirthValidator,
  email: z
    .string({
      required_error: "Email is required",
    })
    .trim()
    .toLowerCase()
    .email({ message: "Invalid email format" })
    .max(255, { message: "Email is too long" }),
  profession: z
    .string({
      required_error: "Profession is required",
    })
    .trim()
    .min(1, { message: "Profession is required" })
    .max(100, { message: "Profession must be less than 100 characters" }),
});

const partialPersonalInformation = z.object({
  legalName: fullNameValidator.optional(),
  dateOfBirth: dateOfBirthValidator.optional(),
  email: z
    .string({
      required_error: "Email is required",
    })
    .trim()
    .toLowerCase()
    .email({ message: "Invalid email format" })
    .max(255, { message: "Email is too long" })
    .optional(),
  profession: z
    .string({
      required_error: "Profession is required",
    })
    .trim()
    .min(1, { message: "Profession is required" })
    .max(100, { message: "Profession must be less than 100 characters" })
    .optional(),
});

export const ownerPersonalInformationSchema = personalInformation
  .extend({
    signingDevicesZip: z.object({
      zipCode1: zipCodeValidator,
      zipCode2: zipCodeValidator.optional(),
      zipCode3: zipCodeValidator.optional(),
    }),
  })
  .superRefine((val, ctx) => {
    const zipMap = new Map<string, string[]>();

    Object.entries(val.signingDevicesZip).forEach(([field, zip]) => {
      if (zip !== undefined) {
        if (!zipMap.has(zip)) {
          zipMap.set(zip, [field]);
        } else {
          zipMap.get(zip)?.push(field);
        }
      }
    });

    for (const [zip, fields] of zipMap) {
      if (fields.length > 1) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Duplicate zip code ${zip} found.`,
          path: ["signingDevicesZip"],
        });

        fields.forEach((field) => {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `This zip code is already used`,
            path: ["signingDevicesZip", field],
          });
        });
      }
    }
  });

const ownerPartialPersonalInformationSchema = partialPersonalInformation
  .extend({
    signingDevicesZip: z.object({
      zipCode1: zipCodeValidator.optional(),
      zipCode2: zipCodeValidator.optional(),
      zipCode3: zipCodeValidator.optional(),
    }),
  })
  .superRefine((val, ctx) => {
    const zipMap = new Map<string, string[]>();

    Object.entries(val.signingDevicesZip).forEach(([field, zip]) => {
      if (zip !== undefined) {
        if (!zipMap.has(zip)) {
          zipMap.set(zip, [field]);
        } else {
          zipMap.get(zip)?.push(field);
        }
      }
    });

    for (const [zip, fields] of zipMap) {
      if (fields.length > 1) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Duplicate zip code ${zip} found.`,
          path: ["signingDevicesZip"],
        });

        fields.forEach((field) => {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `This zip code is already used`,
            path: ["signingDevicesZip", field],
          });
        });
      }
    }
  });

const baseKeyHolderSchema = z.object({
  keyHolders: z
    .object({
      owner_held_amount: z.union([
        z.literal("1"),
        z.literal("2"),
        z.literal("3"),
      ]),
      owner: ownerPersonalInformationSchema,
      keyHolder1: personalInformation.optional(),
      keyHolder2: personalInformation.optional(),
    })
    .superRefine((val, ctx) => {
      if (
        val.owner_held_amount === "1" &&
        (!val.keyHolder1 || !val.keyHolder2)
      ) {
        if (!val.keyHolder1) {
          return ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Please provide at least one key holder",
            path: ["keyHolder1"],
          });
        }

        if (!val.keyHolder2) {
          return ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Please provide at least one key holder",
            path: ["keyHolder2"],
          });
        }

        return ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Please provide at least one key holder",
          path: ["keyHolder1", "keyHolder2"],
        });
      }

      if (val.owner_held_amount === "2" && !val.keyHolder1) {
        return ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Please provide the second key holder",
          path: ["keyHolder1"],
        });
      }

      if (val.owner_held_amount === "3") {
        const zipCode2 = val.owner.signingDevicesZip.zipCode2;
        const zipCode3 = val.owner.signingDevicesZip.zipCode3;

        if (zipCode2 === undefined && zipCode3 === undefined) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Please provide the second key's zip code",
            path: ["owner.signingDevicesZip.zipCode2"],
          });

          return ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Please provide the third key's zip code",
            path: ["owner.signingDevicesZip.zipCode3"],
          });
        }

        if (!zipCode2) {
          return ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Please provide the second key's zip code",
            path: ["owner.signingDevicesZip.zipCode2"],
          });
        }

        if (!zipCode3) {
          return ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Please provide the third key's zip code",
            path: ["owner.signingDevicesZip.zipCode3"],
          });
        }
      }
    }),
  criminalInvestigation: multiChoiceValidator,
  felonyOrDishonesty: multiChoiceValidator,
  lossesPrivateKey: multiChoiceValidator,
  lossesPersonalHoldings: multiChoiceValidator,
  kidnapping: multiChoiceValidator,
  privateSecurity: multiChoiceValidator,
  detailedDescription: z.string().trim().max(10_000).optional(),
});

export type KeyHolder = z.infer<typeof keyHolderSchema>;
export const keyHolderSchema = baseKeyHolderSchema.superRefine((val, ctx) => {
  const isAnyTrue = Object.values(val).some((v) => v === true);
  if (isAnyTrue && !val.detailedDescription) {
    return ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `Please provide a detailed description of the circumstances surrounding the "Yes" answers.`,
      path: ["detailedDescription"],
    });
  }

  const emailMap = new Map<string, string[]>();
  const addEmail = (email: string | undefined, source: string) => {
    if (email) {
      if (!emailMap.has(email)) {
        emailMap.set(email, [source]);
      } else {
        emailMap.get(email)?.push(source);
      }
    }
  };

  addEmail(val.keyHolders.owner.email, "owner");
  if (val.keyHolders.keyHolder1?.email) {
    addEmail(val.keyHolders.keyHolder1.email, "keyHolder1");
  }
  if (val.keyHolders.keyHolder2?.email) {
    addEmail(val.keyHolders.keyHolder2.email, "keyHolder2");
  }

  for (const [, sources] of emailMap) {
    if (sources.length > 1) {
      sources.forEach((source) => {
        if (source !== "owner") {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `This email is already used by ${sources
              .filter((s) => s !== source)
              .map((s) =>
                s === "owner"
                  ? "Named Insured"
                  : s === "keyHolder1"
                    ? "Key Holder 1"
                    : "Key Holder 2",
              )
              .join(", ")}`,
            path: ["keyHolders", source, "email"],
          });
        }
      });
    }
  }
});

export const partialKeyHolderSchema = z
  .object({
    keyHolders: z
      .object({
        owner_held_amount: z.union([
          z.literal("1"),
          z.literal("2"),
          z.literal("3"),
        ]),
        owner: ownerPartialPersonalInformationSchema,
        keyHolder1: partialPersonalInformation.partial().optional(),
        keyHolder2: partialPersonalInformation.partial().optional(),
      })
      .superRefine((val, ctx) => {
        // Email validation logic here, moved from parent schema
        const emailMap = new Map<string, string[]>();

        const addEmail = (email: string | undefined, source: string) => {
          if (email) {
            if (!emailMap.has(email)) {
              emailMap.set(email, [source]);
            } else {
              emailMap.get(email)?.push(source);
            }
          }
        };

        if (val.owner?.email) {
          addEmail(val.owner.email, "owner");
        }
        if (val.keyHolder1?.email) {
          addEmail(val.keyHolder1.email, "keyHolder1");
        }
        if (val.keyHolder2?.email) {
          addEmail(val.keyHolder2.email, "keyHolder2");
        }

        for (const [, sources] of emailMap) {
          if (sources.length > 1) {
            sources.forEach((source) => {
              if (source !== "owner") {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: `This email is already used by ${sources
                    .filter((s) => s !== source)
                    .map((s) =>
                      s === "owner"
                        ? "Named Insured"
                        : s === "keyHolder1"
                          ? "Key Holder 1"
                          : "Key Holder 2",
                    )
                    .join(", ")}`,
                  path: [source, "email"],
                });
              }
            });
          }
        }
      }),
    criminalInvestigation: multiChoiceValidator,
    felonyOrDishonesty: multiChoiceValidator,
    lossesPrivateKey: multiChoiceValidator,
    lossesPersonalHoldings: multiChoiceValidator,
    kidnapping: multiChoiceValidator,
    privateSecurity: multiChoiceValidator,
    detailedDescription: z.string().trim().max(10_000).optional(),
  })
  .partial();

export type PartialKeyHolder = z.infer<typeof partialKeyHolderSchema>;
