import { z } from "zod";
import {
  dateOfBirthValidator,
  fullNameValidator,
  positiveNumberMax1000,
  unitedStatesAddressSchema,
  zPhoneNumber,
} from "../shared";

export type PersonalDetails = z.infer<typeof personalDetailsFlowSchema>;
export const personalDetailsFlowSchema = z.object({
  legalName: fullNameValidator,
  dateOfBirth: dateOfBirthValidator,
  profession: z
    .string({
      required_error: "Please enter your profession",
    })
    .trim()
    .min(1, { message: "Profession is required" })
    .max(100),
  phoneNumber: zPhoneNumber,
  email: z
    .string({
      required_error: "Email is required",
    })
    .email({ message: "Invalid email format" })
    .toLowerCase()
    .trim(),
  primaryAddress: unitedStatesAddressSchema,
  sameAddress: z.boolean(),
  mailingAddress: unitedStatesAddressSchema.optional(),
  residenceDurationOfStay: positiveNumberMax1000,
});

export const partialPersonalDetailsFlowSchema = z
  .object({
    legalName: fullNameValidator,
    dateOfBirth: dateOfBirthValidator,
    profession: z.string().max(100),
    phoneNumber: zPhoneNumber,
    email: z
      .string()
      .email({ message: "Invalid email format" })
      .toLowerCase()
      .trim(),
    primaryAddress: unitedStatesAddressSchema.partial(),
    sameAddress: z.boolean(),
    mailingAddress: unitedStatesAddressSchema.partial().optional(),
    residenceDurationOfStay: positiveNumberMax1000,
  })
  .partial();

export type PartialPersonalDetails = z.infer<
  typeof partialPersonalDetailsFlowSchema
>;
