import { z } from "zod";
import { multiChoiceValidator } from "../shared";

export type SpendingBehavior = z.infer<typeof spendingBehaviorSchema>;
export const spendingBehaviorSchema = z.object({
  sendBitcoinDuringPolicy: multiChoiceValidator,
  monthlySpendingLimit: z
    .union([
      z.literal("0%"),
      z.literal("10%"),
      z.literal("25%"),
      z.literal("50%"),
      z.literal("greater_than_50%"),
    ])
    .optional()
    .refine((value) => value !== undefined, {
      message: "Please select a monthly spending limit",
    }),
  coolDownPeriod: z
    .union([z.literal("3_days"), z.literal("7_days")])
    .optional()
    .refine((value) => value !== undefined, {
      message: "Please select a cool down period",
    }),
});

export const partialSpendingBehavior = spendingBehaviorSchema.partial();
export type PartialSpendingBehavior = z.infer<typeof partialSpendingBehavior>;
