import { z } from "zod";
import { multiChoiceValidator } from "../shared";

export type GovernanceInfo = z.infer<typeof governanceSchema>;
export type PartialGovernanceInfo = z.infer<typeof partialGovernanceSchema>;

export const governanceSchema = z.object({
  hasComplianceDocumentation: multiChoiceValidator,
  providesComplianceTraining: z
    .object({
      answer: multiChoiceValidator,
      details: z.string().trim().max(10_000).optional(),
    })
    .superRefine((val, ctx) => {
      if (val.answer === true && !val.details) {
        return ctx.addIssue({
          code: "custom",
          message: "Details are required",
          path: ["details"],
        });
      }

      return val;
    }),
  hasCertifications: z
    .object({
      answer: multiChoiceValidator,
      details: z.string().trim().max(10_000).optional(),
    })
    .superRefine((val, ctx) => {
      if (val.answer === true && !val.details) {
        return ctx.addIssue({
          code: "custom",
          message: "Details are required",
          path: ["details"],
        });
      }
      return val;
    }),
  participatesInAudits: z
    .object({
      answer: multiChoiceValidator,
      details: z.string().trim().max(10_000).optional(),
    })
    .superRefine((val, ctx) => {
      if (val.answer === true && !val.details) {
        return ctx.addIssue({
          code: "custom",
          message: "Details are required",
          path: ["details"],
        });
      }
      return val;
    }),
  preventsInternalCollusion: z
    .object({
      answer: multiChoiceValidator,
      details: z.string().trim().max(10_000).optional(),
    })
    .superRefine((val, ctx) => {
      if (val.answer === true && !val.details) {
        return ctx.addIssue({
          code: "custom",
          message: "Details are required",
          path: ["details"],
        });
      }
      return val;
    }),
  hasVendorRiskManagement: z
    .object({
      answer: multiChoiceValidator,
      details: z.string().trim().max(10_000).optional(),
    })
    .superRefine((val, ctx) => {
      if (val.answer === true && !val.details) {
        return ctx.addIssue({
          code: "custom",
          message: "Details are required",
          path: ["details"],
        });
      }
      return val;
    }),
  requiresSecurityTraining: z
    .object({
      answer: multiChoiceValidator,
      details: z.string().trim().max(10_000).optional(),
    })
    .superRefine((val, ctx) => {
      if (val.answer === true && !val.details) {
        return ctx.addIssue({
          code: "custom",
          message: "Details are required",
          path: ["details"],
        });
      }
      return val;
    }),
  hasNetworkSecurityPolicy: z
    .object({
      answer: multiChoiceValidator,
      details: z.string().trim().max(10_000).optional(),
    })
    .superRefine((val, ctx) => {
      if (val.answer === true && !val.details) {
        return ctx.addIssue({
          code: "custom",
          message: "Details are required",
          path: ["details"],
        });
      }
      return val;
    }),
  controlsSensitiveAccess: z
    .object({
      answer: multiChoiceValidator,
      details: z.string().trim().max(10_000).optional(),
      reviewProcess: z.string().trim().max(10_000).optional(),
    })
    .superRefine((val, ctx) => {
      if (val.answer === true && !val.details) {
        return ctx.addIssue({
          code: "custom",
          message: "Details are required",
          path: ["details"],
        });
      }

      if (val.answer === true && !val.reviewProcess) {
        return ctx.addIssue({
          code: "custom",
          message: "Review process is required",
          path: ["reviewProcess"],
        });
      }

      return val;
    }),
  operatesInSanctionedRegions: multiChoiceValidator,
});

export const partialGovernanceSchema = z
  .object({
    hasComplianceDocumentation: multiChoiceValidator,
    providesComplianceTraining: z.object({
      answer: multiChoiceValidator,
      details: z.string().trim().max(10_000).optional(),
    }),
    hasCertifications: z.object({
      answer: multiChoiceValidator,
      details: z.string().trim().max(10_000).optional(),
    }),
    participatesInAudits: z.object({
      answer: multiChoiceValidator,
      details: z.string().trim().max(10_000).optional(),
    }),
    preventsInternalCollusion: z.object({
      answer: multiChoiceValidator,
      details: z.string().trim().max(10_000).optional(),
    }),
    hasVendorRiskManagement: z.object({
      answer: multiChoiceValidator,
      details: z.string().trim().max(10_000).optional(),
    }),
    requiresSecurityTraining: z.object({
      answer: multiChoiceValidator,
      details: z.string().trim().max(10_000).optional(),
    }),
    hasNetworkSecurityPolicy: z.object({
      answer: multiChoiceValidator,
      details: z.string().trim().max(10_000).optional(),
    }),
    controlsSensitiveAccess: z.object({
      answer: multiChoiceValidator,
      details: z.string().trim().max(10_000).optional(),
      reviewProcess: z.string().trim().max(10_000).optional(),
    }),
    operatesInSanctionedRegions: multiChoiceValidator,
  })
  .deepPartial();
