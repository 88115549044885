import { z } from "zod";
import {
  dateOfBirthValidator,
  fullNameValidator,
  multiChoiceValidator,
} from "../shared";

export const companyLeaderValidator = z.object({
  name: fullNameValidator,
  birthday: dateOfBirthValidator,
  email: z
    .string({
      required_error: "Email is required",
    })
    .trim()
    .email({ message: "Invalid email format" })
    .max(255, { message: "Email is too long" }),
  job: z
    .string({
      required_error: "Job is required",
    })
    .trim()
    .min(1, { message: "Job is required" })
    .max(100, { message: "Job title is too long" }),
  areas: z
    .string({
      required_error: "Areas are required",
    })
    .trim()
    .min(1, { message: "Areas are required" })
    .max(1_000, { message: "Areas is too long" }),
});

const roles = z
  .array(
    z
      .union([
        z.literal("executive"),
        z.literal("digital-asset-manager"),
        z.literal("shareholder-25"),
      ])
      .optional(),
  )
  .refine((value) => value.some((item) => item), {
    message: "You have to select at least one item.",
  });

const partialRoles = z.array(
  z
    .union([
      z.literal("executive"),
      z.literal("digital-asset-manager"),
      z.literal("shareholder-25"),
    ])
    .optional(),
);

const baseCompanyLeaderSchema = z.object({
  criminal_investigation: multiChoiceValidator,
  felony_or_dishonesty_conviction: multiChoiceValidator,
  losses_due_to_private_key: multiChoiceValidator,
  personal_holdings_losses: multiChoiceValidator,
  kidnapping_experience: multiChoiceValidator,
  private_security: multiChoiceValidator,
  company_leaders: z
    .array(companyLeaderValidator.extend({ roles }))
    .superRefine((val, ctx) => {
      const emailMap = new Map<string, number[]>();

      val.forEach((leader, index) => {
        if (leader.email) {
          if (!emailMap.has(leader.email)) {
            emailMap.set(leader.email, [index]);
          } else {
            emailMap.get(leader.email)?.push(index);
          }
        }
      });

      for (const [, indices] of emailMap) {
        if (indices.length > 1) {
          indices.forEach((index) => {
            // dont show error for policy owner
            if (index > 0) {
              ctx.addIssue({
                code: "custom",
                message: `This email is already used by Company Leader ${indices
                  .filter((i) => i !== index)
                  .map((i) => i + 1)
                  .join(", ")}`,
                path: [index, "email"],
              });
            }
          });
        }
      }
    }),
  detailed_description: z.string().optional(),
});

export type CompanyLeaderDetails = z.infer<typeof companyLeaderDetailSchema>;
export const companyLeaderDetailSchema = baseCompanyLeaderSchema.superRefine(
  (val, ctx) => {
    const missing_description = !val.detailed_description;
    if (
      (val.criminal_investigation === true && missing_description) ||
      (val.felony_or_dishonesty_conviction === true && missing_description) ||
      (val.losses_due_to_private_key === true && missing_description) ||
      (val.personal_holdings_losses === true && missing_description) ||
      (val.kidnapping_experience === true && missing_description) ||
      (val.private_security === true && missing_description)
    ) {
      ctx.addIssue({
        code: "custom",
        message: `Description is required because you answered "Yes" to one of the questions above.`,
        path: ["detailed_description"],
      });
    }
  },
);

const deepPartialCompanyLeaderSchema = z.object({
  criminal_investigation: z.boolean().optional(),
  felony_or_dishonesty_conviction: z.boolean().optional(),
  losses_due_to_private_key: z.boolean().optional(),
  personal_holdings_losses: z.boolean().optional(),
  kidnapping_experience: z.boolean().optional(),
  private_security: z.boolean().optional(),
  company_leaders: z
    .array(
      companyLeaderValidator
        .partial()
        .extend({ roles: partialRoles })
        .partial(),
    )
    .superRefine((val, ctx) => {
      if (val && val.length > 0) {
        const emailMap = new Map<string, number[]>();

        val.forEach((leader, index) => {
          if (leader?.email) {
            if (!emailMap.has(leader.email)) {
              emailMap.set(leader.email, [index]);
            } else {
              emailMap.get(leader.email)?.push(index);
            }
          }
        });

        for (const [, indices] of emailMap) {
          if (indices.length > 1) {
            indices.forEach((index) => {
              // dont show error for policy owner
              if (index > 0) {
                ctx.addIssue({
                  code: "custom",
                  message: `This email is already used by Company Leader ${indices
                    .filter((i) => i !== index)
                    .map((i) => i + 1)
                    .join(", ")}`,
                  path: [index, "email"],
                });
              }
            });
          }
        }
      }
    }),
  detailed_description: z.string().optional(),
});

export const partialCompanyLeaderSchema = deepPartialCompanyLeaderSchema
  .partial()
  .superRefine((val, ctx) => {
    if (
      val.criminal_investigation === true ||
      val.felony_or_dishonesty_conviction === true ||
      val.losses_due_to_private_key === true ||
      val.personal_holdings_losses === true ||
      val.kidnapping_experience === true ||
      val.private_security === true
    ) {
      if (!val.detailed_description) {
        ctx.addIssue({
          code: "custom",
          message: `Description is required because you answered "Yes" to one of the questions above.`,
          path: ["detailed_description"],
        });
      }
    }
  });

export type PartialCompanyLeaderDetails = z.infer<
  typeof partialCompanyLeaderSchema
>;
