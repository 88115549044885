import { z } from "zod";
import {
  addressSchema,
  dateOfBirthValidator,
  fullNameValidator,
  positiveNumberMax1000,
  zipCodeValidator,
} from "../shared";

export type MainPersonalDetails = z.infer<typeof mainPersonalDetailformSchema>;
export const mainPersonalDetailformSchema = z.object({
  legalName: fullNameValidator,
  dateOfBirth: dateOfBirthValidator,
  primaryAddress: addressSchema,
  sameAddress: z.boolean(),
  mailingAddress: addressSchema.optional(),
  signingDeviceZipCode: zipCodeValidator,
  residenceDurationOfStay: positiveNumberMax1000,
});

export const partialMainPersonalDetailformSchema =
  mainPersonalDetailformSchema.deepPartial();

export type PartialMainPersonalDetails = z.infer<
  typeof partialMainPersonalDetailformSchema
>;
