import { z } from "zod";
import { zPhoneNumber } from "../shared";

export const policyOwnerInfoFormSchema = z.object({
  fullName: z
    .string({
      required_error: "Full name is required",
    })
    .trim()
    .min(1, { message: "Full name is required" })
    .max(100, { message: "Full name is too long" }),
  jobTitle: z
    .string({
      required_error: "Job title is required",
    })
    .trim()
    .min(1, { message: "Job title is required" })
    .max(100, { message: "Job title is too long" }),
  email: z
    .string({
      required_error: "Email is required",
    })
    .trim()
    .min(1, { message: "Email is required" })
    .email(),
  phoneNumber: zPhoneNumber,
});

export const partialPolicyOwnerInfoFormSchema = z.object({
  fullName: z
    .string({
      required_error: "Full name is required",
    })
    .trim()
    .max(100, { message: "Full name is too long" })
    .optional(),
  jobTitle: z
    .string({
      required_error: "Job title is required",
    })
    .trim()
    .max(100, { message: "Job title is too long" })
    .optional(),
  email: z
    .string({
      required_error: "Email is required",
    })
    .trim()
    .max(255, { message: "Email is too long" })
    .email()
    .toLowerCase()
    .trim(),
  phoneNumber: zPhoneNumber.optional(),
});

export type PolicyOwnerInfoForm = z.infer<typeof policyOwnerInfoFormSchema>;
export type PartialPolicyOwnerInfoForm = z.infer<
  typeof partialPolicyOwnerInfoFormSchema
>;
