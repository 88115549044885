import { z } from "zod";
import {
  countryValidator,
  dateOfIncorporationValidator,
  stringToBooleanOrBoolean,
  unitedStatesAddressSchema,
  zipCodeValidator,
} from "../shared";

export type BusinessSummaryForm = z.infer<typeof businessSummaryFormSchema>;

const einOrTaxIdNumber = z
  .string({
    required_error: "EIN or TAX ID Number is required",
  })
  .trim()
  .refine((val) => /^\d{9}$|^\d{2}-\d{7}$/.test(val), {
    message: "Must be a valid 9-digit EIN or Tax ID Number",
  })
  .transform((val) => {
    const cleaned = val.replace(/\D/g, "");
    // Format as NN-NNNNNNN
    return cleaned.slice(0, 2) + "-" + cleaned.slice(2);
  })
  .refine(
    (val) => {
      return val.length === 10;
    },
    {
      message:
        "EIN or Tax ID Number must be 9 digits (10 characters including the hyphen)",
    },
  )
  .refine(
    (val) => {
      const firstTwoDigits = parseInt(val.slice(0, 2));
      return (
        firstTwoDigits >= 1 && firstTwoDigits <= 99 && firstTwoDigits !== 47
      );
    },
    {
      message: "Invalid EIN or Tax ID Number",
    },
  );

const baseBusinessSummarySchema = z.object({
  businessEntityName: z
    .string({
      required_error: "Business name is required",
    })
    .trim()
    .min(1, { message: "Business name is required" })
    .max(100, { message: "Business name is too long" }),
  additionalBrandNames: z.string().optional(),
  dateOfIncorporation: dateOfIncorporationValidator,
  registrationType: z
    .union([
      z.literal("llc"),
      z.literal("other"),
      z.literal("corporation"),
      z.literal("c_corporation"),
      z.literal("s_corporation"),
      z.literal("partnership"),
      z.literal("sole_proprietorship"),
    ])
    .optional()
    .refine((value) => value !== undefined, {
      message: "Registration type is required",
    }),
  einOrTaxIdNumber: einOrTaxIdNumber,
  company_info: unitedStatesAddressSchema,
  has_additional_address: z.boolean(),
  business_info: z
    .object({
      addressLine1: z
        .string({
          required_error: "Address is required",
        })
        .trim()
        .min(1, { message: "Address is required" })
        .max(255, { message: "Address is too long" }),
      city: z.string().trim().min(1, { message: "City is required" }).max(255),
      stateOrProvince: z
        .string()
        .trim()
        .min(1, { message: "State is required" })
        .max(255),
      country: countryValidator,
      zipCode: zipCodeValidator,
    })
    .optional(),
  industrySector: z
    .union([
      z.literal("family_or_multi_family_office"),
      z.literal("hedge_fund_or_similar"),
      z.literal("miner"),
      z.literal("mining_pool"),
      z.literal("custodian"),
      z.literal("exchange"),
      z.literal("bitcoin_financial_services"),
      z.literal("other_bitcoin_services"),
      z.literal("ria_or_investment_advisor"),
      z.literal("traditional_financial_services"),
      z.literal("other"),
    ])
    .optional()
    .refine((value) => value !== undefined, {
      message: "Industry sector is required",
    }),
  primaryBusinessModel: z
    .string({
      required_error: "Primary business model is required",
    })
    .trim()
    .min(1, { message: "Primary business model is required" })
    .max(10_000, { message: "Primary business model is too long" }),
  additionalRevenueStreams: z
    .string()
    .trim()
    .max(10_000, { message: "Additional Revenue Streams is too long" })
    .optional(),
  sourceOfCapital: z
    .union([
      z.literal("bootstrapped"),
      z.literal("venture_backing"),
      z.literal("n/a"),
      z.literal("other"),
    ])
    .optional()
    .refine((value) => value !== undefined, {
      message: "Source of capital is required",
    }),
  entityOwnershipType: z
    .union([
      z.literal("single_private_owner"),
      z.literal("multiple_private_owners"),
      z.literal("venture_backed"),
      z.literal("publicly_traded"),
    ])
    .optional()
    .refine((value) => value !== undefined, {
      message: "Entity ownership is required",
    }),
  numberOfEmployees: z
    .number()
    .positive({ message: "Number of employees must be a positive number" })
    .min(1, { message: "Number of employees is required" })
    .max(10_000_000, { message: "Number of employees is too high" })
    .optional()
    .refine((value) => value !== undefined, {
      message: "Number of employees is required",
    }),
  license: z.object({
    has: z.boolean().optional(),
    licenses_details: z.string().optional(),
  }),
  audited_financials: stringToBooleanOrBoolean
    .optional()
    .refine((value) => value !== undefined, {
      message: "Audited financials is required",
    }),

  bankruptcy: z.object({
    hasFiledBankruptcy: stringToBooleanOrBoolean.optional(),
    bankruptcyDetails: z.string().trim().max(10_000).optional(),
  }),

  penTesting: z.object({
    completedPenTesting: stringToBooleanOrBoolean.optional(),
    penTestingAuditDetails: z.string().trim().max(10_000).optional(),
  }),
});

export const businessSummaryFormSchema = baseBusinessSummarySchema.superRefine(
  (val, ctx) => {
    if (val.has_additional_address === true) {
      if (val.business_info === undefined) {
        ctx.addIssue({
          code: "custom",
          message: "Secondary address is required",
          path: ["business_info"],
        });
      }
    }

    if (
      val.license.has === true &&
      (val.license.licenses_details === "" ||
        val.license.licenses_details === undefined)
    ) {
      ctx.addIssue({
        code: "custom",
        message: "Licenses details is required",
        path: ["license", "licenses_details"],
      });
    }

    if (val.bankruptcy.hasFiledBankruptcy === true) {
      if (
        !val.bankruptcy.bankruptcyDetails ||
        val.bankruptcy.bankruptcyDetails === ""
      ) {
        ctx.addIssue({
          code: "custom",
          message: "Bankruptcy details is required",
          path: ["bankruptcy", "bankruptcyDetails"],
        });
      }
    }

    if (val.penTesting.completedPenTesting === true) {
      if (
        val.penTesting.penTestingAuditDetails === undefined ||
        val.penTesting.penTestingAuditDetails === ""
      ) {
        ctx.addIssue({
          code: "custom",
          message: "Pen testing audit details is required",
          path: ["penTesting", "penTestingAuditDetails"],
        });
      }
    }

    return val;
  },
);

export const partialBusinessSummaryFormSchema =
  baseBusinessSummarySchema.deepPartial();

export type PartialBusinessSummaryForm = z.infer<
  typeof partialBusinessSummaryFormSchema
>;
