import { z } from "zod";

export const bitcoinAddressValidation = z
  .string()
  .trim()
  .superRefine((val, ctx) => {
    if (
      /^([a-km-zA-HJ-NP-Z1-9]{26,35}|[a-km-zA-HJ-NP-Z1-9]{80}|[a-z]{2,5}1[ac-hj-np-z02-9]{8,100}|[A-Z]{2,5}1[AC-HJ-NP-Z02-9]{8,100}|04[a-fA-F0-9]{128}|(02|03)[a-fA-F0-9]{64})$/.test(
        val,
      )
    ) {
      return true;
    }

    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Invalid address.",
    });
  });

// const testAddresses = [
//   "17VZNX1SN5NtKa8UQFxwQbFeFc3iqRYhem",
//   "mipcBbFg9gMiCh81Kj8tqqdgoZub1ZJRfn",
//   "17VZNX1SN5NtKa8UFFxwQbFeFc3iqRYhem",
//   "3J98t1WpEZ73CNmQviecrnyiWrnqRhWNLy",
//   "2MzQwSSnBHWHqSAqtTVQ6v47XtaisrJa1Vc",
//   "17VZNX1SN5NtKa8UFFxwQbFFFc3iqRYhem",
//   "x",
//   "bc1qw508d6qejxtdg4y5r3zarvary0c5xw7kv8f3t4",
//   "bc1q973xrrgje6etkkn9q9azzsgpxeddats8ckvp5s",
//   "BC1Q973XRRGJE6ETKKN9Q9AZZSGPXEDDATS8CKVP5S",
//   "BC1QW508D6QEJXTDG4Y5R3ZARVARY0C5XW7KV8F3T4",
//   "tb1qw508d6qejxtdg4y5r3zarvary0c5xw7kxpjzsx",
//   "bcrt1q6z64a43mjgkcq0ul2znwneq3spghrlau9slefp",
//   "bc1ptxs597p3fnpd8gwut5p467ulsydae3rp9z75hd99w8k3ljr9g9rqx6ynaw",
//   "tb1p84x2ryuyfevgnlpnxt9f39gm7r68gwtvllxqe5w2n5ru00s9aquslzggwq",
// ];

// const test = () => {
//   testAddresses.forEach((address) => {
//     try {
//       bitcoinAddressValidation.parse(address);
//       console.log(`✅ ${address} is a valid address`);
//     } catch (error) {
//       console.log(`❌ ${address} is not a valid address`);
//     }
//   });
// };

// test();
