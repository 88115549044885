import { z } from "zod";
import { partialBusinessSummaryFormSchema } from "./main/business_summary";
import { partialCompanyLeaderSchema } from "./main/company_leaders";
import { partialGovernanceSchema } from "./main/governance";
import { partialInsuranceNeedsSchema } from "./main/insurance_needs";
import { partialPolicyOwnerInfoFormSchema } from "./main/policy-owner-info";
import { partialSpendingBehavior } from "./main/spending_behavior";
import { partialBeneficiariesSchema } from "./personal/beneficiaries";
import { partialKeyHolderSchema } from "./personal/key_holders";
import { partialKeyHolderPersonalDetailsFlowSchema } from "./personal_details_forms/key_holder";
import { partialMainPersonalDetailformSchema } from "./personal_details_forms/main";
import { partialPersonalDetailsFlowSchema } from "./personal_details_forms/personal";
import { whitelistAndCancelationAddressFormSchema } from "./main/whitelist";
import { partialStakeholderFlowSchema } from "./personal_details_forms/stakeholder";

export const formVariants = {
  policyOwnerInformation: "policyOwnerInformation" as const,
  businessSummary: "businessSummary" as const,
  companyLeaderDetails: "companyLeaderDetails" as const,
  personalDetailsMain: "personalDetailsMain" as const,
  personalDetailsStakeholder: "personalDetailsStakeholder" as const,
  personalDetailsKeyHolder: "personalDetailsKeyHolder" as const,
  personalDetailsPersonal: "personalDetailsPersonal" as const,
  insuranceNeeds: "insuranceNeeds" as const,
  governance: "governance" as const,
  spendingBehavior: "spendingBehavior" as const,
  whitelistAddresses: "whitelistAddresses" as const,
  beneficiaries: "beneficiaries" as const,
  keyHolders: "keyHolders" as const,
};

const policyOwnerStepData = z.object({
  step: z.literal("policyOwnerInformation"),
  data: partialPolicyOwnerInfoFormSchema,
});

export const businessSummaryStepData = z.object({
  step: z.literal("businessSummary"),
  data: partialBusinessSummaryFormSchema,
});
export type BusinessSummaryInfo = z.infer<typeof businessSummaryStepData>;

const companyLeaderStepData = z.object({
  step: z.literal("companyLeaderDetails"),
  data: partialCompanyLeaderSchema,
});

const personalDetailsMainStepData = z.object({
  step: z.literal("personalDetailsMain"),
  data: partialMainPersonalDetailformSchema,
});

// const personalDetailsExecAndAssetManagerStepData = z.object({
//   step: z.literal("personalDetailsExecAndAssetManager"),
//   data: partialExecutiveAndAssetManagerFlowSchema,
// });

const personalDetailsKeyHolderStepData = z.object({
  step: z.literal("personalDetailsKeyHolder"),
  data: partialKeyHolderPersonalDetailsFlowSchema,
});

const personalDetailsPersonalStepData = z.object({
  step: z.literal("personalDetailsPersonal"),
  data: partialPersonalDetailsFlowSchema,
});

// const personalDetailsShareholderStepData = z.object({
//   step: z.literal("personalDetailsShareholder"),
//   data: partialShareholderPersonalDetailFlowSchema,
// });

const personalDetailsStakeholderData = z.object({
  step: z.literal("personalDetailsStakeholder"),
  data: partialStakeholderFlowSchema,
});

const insuranceNeedsStepData = z.object({
  step: z.literal("insuranceNeeds"),
  data: partialInsuranceNeedsSchema,
});

const governanceStepData = z.object({
  step: z.literal("governance"),
  data: partialGovernanceSchema,
});

const spendingBehaviorStepData = z.object({
  step: z.literal("spendingBehavior"),
  data: partialSpendingBehavior,
});

const whitelistAddressesStepData = z.object({
  step: z.literal("whitelistAddresses"),
  data: whitelistAndCancelationAddressFormSchema,
});

const beneficiariesStepData = z.object({
  step: z.literal("beneficiaries"),
  data: partialBeneficiariesSchema,
});
export type BeneficiariesStepDataInfo = z.infer<typeof beneficiariesStepData>;

const keyHoldersStepData = z.object({
  step: z.literal("keyHolders"),
  data: partialKeyHolderSchema,
});

const setPartialDataSchema = z.discriminatedUnion("step", [
  policyOwnerStepData,
  businessSummaryStepData,
  companyLeaderStepData,
  personalDetailsMainStepData,
  personalDetailsKeyHolderStepData,
  personalDetailsPersonalStepData,
  personalDetailsStakeholderData,
  insuranceNeedsStepData,
  governanceStepData,
  spendingBehaviorStepData,
  whitelistAddressesStepData,
  beneficiariesStepData,
  keyHoldersStepData,
]);

export const arrayPartialDataSchema = z.array(setPartialDataSchema);
export type ArrayPartialDataType = z.infer<typeof arrayPartialDataSchema>;
export type setPartialDataType = z.infer<typeof setPartialDataSchema>;

const status = z.union([
  z.literal("incomplete"),
  z.literal("complete"),
  z.literal("pending"),
]);

export const formStatuses = z
  .object({
    policyOwnerInfo: status,
    businessSummary: status,
    companyLeaderDetails: status,
    personalDetailsMain: status,
    personalDetailsKeyHolder: status,
    personalDetailsPersonal: status,
    personalDetailsStakeholder: status,
    insuranceNeeds: status,
    governance: status,
    spendingBehavior: status,
    whitelistAddresses: status,
    beneficiaries: status,
    keyHolders: status,
    identityIntro: status,
    identityKyc: status,
    identityStatus: status,
    upload: status,
    identity: status,
    payment: status,
  })
  .partial();

export type FormStatuses = z.infer<typeof formStatuses>;

export const enterpriseFlowFormStatusesSchema = z
  .object({
    policyOwnerInfo: status,
    businessSummary: status,
    companyLeaderDetails: status,
    insuranceNeeds: status,
    governance: status,
    spendingBehavior: status,
    whitelistAddresses: status,
    beneficiaries: status,
    identityIntro: status,
    identityKyc: status,
    identityStatus: status,
    upload: status,
    payment: status,
  })
  .partial();

export type EnterpriseFlowFormStatuses = z.infer<
  typeof enterpriseFlowFormStatusesSchema
>;

export const personalFlowFormStatuses = z
  .object({
    policyOwnerInfo: status,
    beneficiaries: status,
    keyHolders: status,
    identityIntro: status,
    identityKyc: status,
    identityStatus: status,
    insuranceNeeds: status,
    spendingBehavior: status,
    whitelistAddresses: status,
    upload: status,
    payment: status,
  })
  .partial();

export const stakeholderFlowFormStatuses = z
  .object({
    personalDetailsStakeholder: status,
    identity: status,
  })
  .partial();

export type StakeholderFlowFormStatuses = z.infer<
  typeof stakeholderFlowFormStatuses
>;

export const keyHolderFlowFormStatuses = z
  .object({
    personalDetailsKeyHolder: status,
    identity: status,
  })
  .partial();

export type KeyHolderFlowFormStatuses = z.infer<
  typeof keyHolderFlowFormStatuses
>;

export type PersonalFlowFormStatuses = z.infer<typeof personalFlowFormStatuses>;
