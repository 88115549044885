import { z } from "zod";
import {
  addressSchema,
  dateOfBirthValidator,
  fullNameValidator,
  positiveNumberMax1000,
  zipCodeValidator,
} from "../shared";

export type KeyHolderPersonalDetails = z.infer<
  typeof keyHolderPersonalDetailsFlowSchema
>;
export const keyHolderPersonalDetailsFlowSchema = z.object({
  legalName: fullNameValidator,
  dateOfBirth: dateOfBirthValidator,
  primaryAddress: addressSchema,
  sameAddress: z.boolean(),
  mailingAddress: addressSchema.optional(),
  signingDeviceZipCode: zipCodeValidator,
  residenceDurationOfStay: positiveNumberMax1000,
});

export const partialKeyHolderPersonalDetailsFlowSchema =
  keyHolderPersonalDetailsFlowSchema.deepPartial();

export type PartialKeyHolderPersonalDetails = z.infer<
  typeof partialKeyHolderPersonalDetailsFlowSchema
>;
