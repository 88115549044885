import { bitcoinAddressValidation } from "@trident/validators/bitcoin-address-validation";
import { z } from "zod";

export type WhitelistAddresAndCancelation = z.infer<
  typeof whitelistAndCancelationAddressFormSchema
>;
export const whitelistAndCancelationAddressFormSchema = z.object({
  cancelationAddress: z.object({
    nickname: z
      .string()
      .trim()
      .min(1, { message: "Nickname is required" })
      .max(20, { message: "Nickname must be less than 20 characters" }),
    address: bitcoinAddressValidation,
  }),
  addresses: z.array(
    z.object({
      nickname: z
        .string()
        .trim()
        .min(1, { message: "Nickname is required" })
        .max(20, { message: "Nickname must be less than 20 characters" }),
      address: bitcoinAddressValidation,
    }),
  ),
});

export type WhitelistAddress = z.infer<typeof whitelistAddressFormSchema>;
export const whitelistAddressFormSchema = z.object({
  addresses: z.array(
    z.object({
      nickname: z
        .string()
        .trim()
        .min(1, { message: "Nickname is required" })
        .max(20, { message: "Nickname must be less than 20 characters" }),
      address: bitcoinAddressValidation,
    }),
  ),
});
