import { z } from "zod";
import { fullNameValidator, zPhoneNumber } from "../shared";

const beneficiaryValidator = z.object({
  name: fullNameValidator,
  entityName: z.string().optional(),
  relationship: z
    .string({
      required_error: "Relationship is required",
    })
    .trim()
    .min(1, { message: "Relationship is required" })
    .max(255, { message: "Too many charactors" }),
  email: z
    .string({
      required_error: "Email is required",
    })
    .trim()
    .toLowerCase()
    .email({ message: "Invalid email format" })
    .max(255, { message: "Email is too long" }),
  phoneNumber: zPhoneNumber,
});

export type Beneficiaries = z.infer<typeof beneficiariesSchema>;
export const beneficiariesSchema = z.object({
  primaryBeneficiary: beneficiaryValidator,
  secondaryBeneficiary: beneficiaryValidator.partial(),
});

export const partialBeneficiariesSchema = z
  .object({
    primaryBeneficiary: beneficiaryValidator.partial(),
    secondaryBeneficiary: beneficiaryValidator.partial(),
  })
  .partial();

export type PartialBeneficiaries = z.infer<typeof partialBeneficiariesSchema>;
