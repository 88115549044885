import { z } from "zod";
import {
  addressSchema,
  dateOfBirthValidator,
  fullNameValidator,
} from "../shared";

/**
 * this will cover 3 forms: executive, digitial asset manager, and shareholder
 */
export type StakeholderPersonalDetails = z.infer<typeof stakeholderFlowSchema>;

export const stakeholderFlowSchema = z.object({
  legalName: fullNameValidator,
  dateOfBirth: dateOfBirthValidator,
  primaryAddress: addressSchema,
});

export const partialStakeholderFlowSchema = stakeholderFlowSchema.deepPartial();

export type PartialStakeholderPersonalDetails = z.infer<
  typeof partialStakeholderFlowSchema
>;
