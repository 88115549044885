import { z } from "zod";
import parsePhoneNumber from "libphonenumber-js";
import { COUNTRIES } from "./countries";
import { STATES } from "./us-states";

// i have no clue what a better way to validate a zip code from anywhere in the world...
export const zipCodeValidator = z
  .string({
    required_error: "Zip code is required",
  })
  .regex(/^[a-z0-9][a-z0-9\- ]{0,10}[a-z0-9]$/i, {
    message: "Invalid zip code",
  });

export const dateOfBirthValidator = z
  .string({
    required_error: "Date of birth is required",
  })
  .superRefine((val, ctx) => {
    const date = new Date(val);
    if (isNaN(date.getTime())) {
      return ctx.addIssue({
        code: "custom",
        message: "Date of birth is invalid",
        path: [],
      });
    }

    if (date > new Date()) {
      return ctx.addIssue({
        code: "custom",
        message: "Date of birth cannot be in the future",
      });
    }

    const minAge = 18;
    const minAgeDate = new Date();
    minAgeDate.setFullYear(minAgeDate.getFullYear() - minAge);
    if (date > minAgeDate) {
      return ctx.addIssue({
        code: "custom",
        message: "You must be at least 18 years old",
      });
    }

    const maxAge = 125;
    const maxAgeDate = new Date();
    maxAgeDate.setFullYear(maxAgeDate.getFullYear() - maxAge);
    if (date < maxAgeDate) {
      return ctx.addIssue({
        code: "custom",
        message: "You must be under 125 years old",
      });
    }

    return val;
  });

export const dateOfIncorporationValidator = z
  .string({
    required_error: "Date of incorporation is required",
  })
  .superRefine((val, ctx) => {
    const date = new Date(val);
    if (isNaN(date.getTime())) {
      return ctx.addIssue({
        code: "custom",
        message: "Date of incorporation is invalid",
        path: [],
      });
    }

    if (date > new Date()) {
      return ctx.addIssue({
        code: "custom",
        message: "Date of incorporation cannot be in the future",
      });
    }

    return val;
  });

export const positiveNumberMax1000 = z.coerce
  .number({
    required_error: "This field is required",
  })
  .finite()
  .nonnegative()
  .max(1_000)
  .optional()
  .refine((val) => val !== undefined, {
    message: "This field is required",
  })
  .refine((val) => val !== 0, {
    message: "This field is required",
  });

export const zPhoneNumber = z
  .string({
    required_error: "Phone number is required",
  })
  .transform((value, ctx) => {
    const phoneNumber = parsePhoneNumber(value, {
      defaultCountry: "US",
    });

    if (!phoneNumber?.isValid()) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message:
          "Invalid phone number, if you are outside the US, please include country code, e.g. +55 31 2128 6800",
      });
      return z.NEVER;
    }

    return phoneNumber.formatInternational();
  });

export const countryValidator = z
  .string({
    required_error: "Please Select A Country",
  })
  .superRefine((val, ctx) => {
    if (!COUNTRIES.some((c) => c.code === val)) {
      return ctx.addIssue({
        code: "custom",
        message: "Invalid country",
      });
    }

    return val;
  });

export const stateValidator = z
  .string({
    required_error: "Please Select A State",
  })
  .superRefine((val, ctx) => {
    if (!STATES.some((c) => c.abbreviation === val)) {
      return ctx.addIssue({
        code: "custom",
        message: "Invalid state",
      });
    }
  });

export const addressSchema = z.object({
  addressLine1: z
    .string({
      required_error: "Address line 1 is required",
    })
    .min(1, { message: "Address line 1 is required" })
    .trim(),
  addressLine2: z.string().trim().optional(),
  city: z
    .string({
      required_error: "City is required",
    })
    .min(1, { message: "City is required" })
    .trim(),
  stateOrProvince: z
    .string({
      required_error: "State or province is required",
    })
    .min(1, { message: "State or province is required" })
    .trim(),
  country: countryValidator,
  zipCode: zipCodeValidator,
});

export const unitedStatesAddressSchema = z.object({
  addressLine1: z
    .string({
      required_error: "Address is required",
    })
    .min(1, { message: "Address is required" })
    .trim()
    .max(255),
  addressLine2: z.string().trim().max(255).optional(),
  city: z
    .string({
      required_error: "City is required",
    })
    .min(1, { message: "City is required" })
    .trim(),
  stateOrProvince: stateValidator,
  country: countryValidator,
  zipCode: zipCodeValidator,
});

export const multiChoiceValidator = z
  .undefined()
  .or(z.coerce.boolean())
  .superRefine((val, ctx) => {
    if (val === undefined) {
      return ctx.addIssue({
        code: "custom",
        message: "Please select an option",
      });
    }

    return val;
  });
// .refine((val) => val !== undefined, {
//   message: "Please select an option",
// });

export const stringToBooleanOrBoolean = z
  .string()
  .transform((val) => {
    if (val === "true") {
      return true;
    } else {
      return false;
    }
  })
  .or(z.boolean());

const MAX_NAME_LENGTH = 255;

export const fullNameValidator = z
  .string({
    required_error: "Legal name is required",
  })
  .min(1, { message: "Legal name is required" })
  .max(MAX_NAME_LENGTH, { message: "Wow, that's a long name" })
  .trim()
  .superRefine((val, ctx) => {
    const validCharacters = /^[\p{L}\s'-]*$/u; // unicode letters, spaces, hyphens, and apostrophes
    const valid = validCharacters.test(val);

    if (!valid) {
      return ctx.addIssue({
        code: "custom",
        message: "Please enter a valid name",
      });
    }

    const atLeastTwoWords = val.split(" ").length >= 2;

    if (!atLeastTwoWords) {
      return ctx.addIssue({
        code: "custom",
        message: "Please enter a first and last name",
      });
    }

    return val;
  });
