import type { BusinessSummaryForm } from "@trident/validators/app-form-validations/main/business_summary";
import type { PartialCompanyLeaderDetails } from "@trident/validators/app-form-validations/main/company_leaders";
import type { PartialGovernanceInfo } from "@trident/validators/app-form-validations/main/governance";
import type { PartialInsuranceNeeds } from "@trident/validators/app-form-validations/main/insurance_needs";
import type { PartialSpendingBehavior } from "@trident/validators/app-form-validations/main/spending_behavior";
import type { WhitelistAddresAndCancelation } from "@trident/validators/app-form-validations/main/whitelist";
import type { PartialBeneficiaries } from "@trident/validators/app-form-validations/personal/beneficiaries";
import type { PartialKeyHolder } from "@trident/validators/app-form-validations/personal/key_holders";
import type { PartialKeyHolderPersonalDetails } from "@trident/validators/app-form-validations/personal_details_forms/key_holder";
import type { PartialMainPersonalDetails } from "@trident/validators/app-form-validations/personal_details_forms/main";
import type { PartialPersonalDetails } from "@trident/validators/app-form-validations/personal_details_forms/personal";
import type { PartialStakeholderPersonalDetails } from "@trident/validators/app-form-validations/personal_details_forms/stakeholder";
import type { DefaultValues } from "react-hook-form";

export const policyOwnerDefaultValues = {
  fullName: "",
  jobTitle: "",
  email: "",
  phoneNumber: "",
};

export const businessSummaryDefaultValues: DefaultValues<BusinessSummaryForm> =
  {
    businessEntityName: "",
    additionalBrandNames: "",
    registrationType: undefined,
    einOrTaxIdNumber: "",
    company_info: {
      addressLine1: "",
      city: "",
      stateOrProvince: undefined,
      country: "",
      zipCode: undefined,
    },
    has_additional_address: false,
    business_info: {
      addressLine1: "",
      city: "",
      stateOrProvince: "",
      country: undefined,
      zipCode: undefined,
    },
    industrySector: undefined,
    primaryBusinessModel: "",
    additionalRevenueStreams: "",
    sourceOfCapital: undefined,
    entityOwnershipType: undefined,
    numberOfEmployees: undefined,
    license: {
      has: undefined,
      licenses_details: "",
    },
    audited_financials: undefined,
    bankruptcy: {
      bankruptcyDetails: "",
      hasFiledBankruptcy: undefined,
    },
    penTesting: {
      completedPenTesting: undefined,
      penTestingAuditDetails: "",
    },
  };

export const beneficiariesDefaultValues: PartialBeneficiaries = {
  primaryBeneficiary: {
    email: undefined,
    entityName: undefined,
    name: undefined,
    phoneNumber: undefined,
    relationship: undefined,
  },
  secondaryBeneficiary: {
    email: undefined,
    entityName: undefined,
    name: undefined,
    phoneNumber: undefined,
    relationship: undefined,
  },
};

export const companyLeaderDefaultValues: PartialCompanyLeaderDetails = {
  company_leaders: Array(3).fill({
    name: undefined,
    birthday: undefined,
    email: undefined,
    job: undefined,
    areas: undefined,
    roles: [],
  }),
  criminal_investigation: undefined,
  detailed_description: undefined,
  felony_or_dishonesty_conviction: undefined,
  kidnapping_experience: undefined,
  losses_due_to_private_key: undefined,
  personal_holdings_losses: undefined,
  private_security: undefined,
};

export const personalDetailsMainDefaultValues: PartialMainPersonalDetails = {
  legalName: "",
  dateOfBirth: "",
  primaryAddress: {
    addressLine1: "",
    addressLine2: "",
    city: "",
    stateOrProvince: "",
    country: undefined,
    zipCode: "",
  },
  mailingAddress: {
    addressLine1: "",
    addressLine2: "",
    city: "",
    stateOrProvince: "",
    country: undefined,
    zipCode: "",
  },
  sameAddress: false,
  signingDeviceZipCode: "",
};

export const personalDetailsPersonalFlowDefaultValues: PartialPersonalDetails =
  {
    legalName: "",
    dateOfBirth: "",
    primaryAddress: {
      addressLine1: "",
      addressLine2: "",
      city: "",
      stateOrProvince: undefined,
      country: undefined,
      zipCode: "",
    },
    mailingAddress: {
      addressLine1: "",
      addressLine2: "",
      city: "",
      stateOrProvince: undefined,
      country: undefined,
      zipCode: "",
    },
    sameAddress: false,
    phoneNumber: "",
    email: "",
    profession: "",
  };

export const insuranceNeedsDefaultValues: DefaultValues<PartialInsuranceNeeds> =
  {
    bitcoinProperty: {
      isOwner: undefined,
      titleHolder: undefined,
    },
    currentInsurancePolicies: {
      currentPolicies: undefined,
      hasCurrentPolicies: undefined,
    },
    otherInsurancePolicies: {
      hasApprovedPolicies: undefined,
      policies: "",
    },
    terminatedInsurance: {
      hasTerminatedInsurance: undefined,
      terminationDetails: undefined,
    },
    bitcoinStorageLocation: {
      self_custody_addresses: [{ address: "" }],
    },
    bitcoinStorageAmount: undefined,
  };

export const governanceDefaultValues: PartialGovernanceInfo = {
  hasComplianceDocumentation: undefined,
  controlsSensitiveAccess: {
    answer: undefined,
  },
  hasCertifications: {
    answer: undefined,
  },
  hasNetworkSecurityPolicy: {
    answer: undefined,
  },
  hasVendorRiskManagement: {
    answer: undefined,
  },
  participatesInAudits: {
    answer: undefined,
  },
  preventsInternalCollusion: {
    answer: undefined,
  },
  providesComplianceTraining: {
    answer: undefined,
  },
  requiresSecurityTraining: {
    answer: undefined,
  },
  operatesInSanctionedRegions: undefined,
};

export const spendingBehaviorDefaultValues: PartialSpendingBehavior = {
  coolDownPeriod: undefined,
  monthlySpendingLimit: undefined,
  sendBitcoinDuringPolicy: undefined,
};

export const whitelistAddressesDefaultValues: WhitelistAddresAndCancelation = {
  cancelationAddress: { address: "", nickname: "" },
  addresses: [{ address: "", nickname: "" }],
};

export const keyholdersDefaultValues: PartialKeyHolder = {
  criminalInvestigation: undefined,
  detailedDescription: undefined,
  privateSecurity: undefined,
  felonyOrDishonesty: undefined,
  kidnapping: undefined,
  lossesPersonalHoldings: undefined,
  lossesPrivateKey: undefined,
  keyHolders: {
    owner_held_amount: "3",
    owner: {
      dateOfBirth: undefined,
      email: undefined,
      legalName: undefined,
      profession: undefined,
      signingDevicesZip: {
        zipCode1: undefined,
        zipCode2: undefined,
        zipCode3: undefined,
      },
    },
  },
};

export const personalDetailsStakeholder: PartialStakeholderPersonalDetails = {
  legalName: "",
  dateOfBirth: "",
  primaryAddress: {
    addressLine1: "",
    addressLine2: "",
    city: "",
    stateOrProvince: "",
    country: undefined,
    zipCode: "",
  },
};

export const personalDetailsKeyHolder: PartialKeyHolderPersonalDetails = {
  legalName: "",
  dateOfBirth: "",
  signingDeviceZipCode: "",
  residenceDurationOfStay: 0,
  primaryAddress: {
    addressLine1: "",
    addressLine2: "",
    city: "",
    stateOrProvince: "",
    country: undefined,
    zipCode: "",
  },
  sameAddress: undefined,
  mailingAddress: {
    addressLine1: "",
    addressLine2: "",
    city: "",
    stateOrProvince: "",
    country: undefined,
    zipCode: "",
  },
};
